<template>
      <v-footer class='colorGradient'>
      <v-row justify='center'>
        <v-col cols="12" xl="8" lg="8" md="9" sm="10" xs="11">
          <v-card class="elevation-0 transparent">
            <v-row justify='center'>
              <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                <v-card  class="elevation-0 transparent">
                  <v-card-text>
                    <h5 class="white--text">ที่อยู่</h5>
                    <v-divider/>
                    <v-row>
                      <v-col cols="12">
                        <v-card class="elevation-0 transparent" >
                          <v-card-text class="pad0 white--text">
                            บริษัท ซอฟต์เวย์ คอนซัลติง จำกัด<br>1768 อาคารไทยซัมมิท ทาวเวอร์ <br>ชั้น 16<br>ถ.เพชรบุรีตัดใหม่ แขวงบางกะปิ<br>เขตห้วยขวาง กรุงเทพมหานคร <br>10310
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                <v-card  class="elevation-0 transparent">
                  <v-card-text class="white--text ">
                    <h5>ติดต่อ</h5>
                    <v-divider></v-divider>
                    <v-row>
                      <v-col cols="6">
                        <v-card

                          class="elevation-0 transparent"
                        >
                          <v-card-text class="pad0 white--text ">
                            CallCenter<br>
                            NOC<br>
                            สายด่วน<br>
                            สอบถามข้อมูล
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col cols="6">
                        <v-card

                          class="elevation-0 transparent"
                        >
                          <v-card-text class="pad0 white--text ">
                            02-257-7000<br>
                            02-257-7111<br>
                            089-166-4966<br>
                            065-523-1692<br>
                            065-521-4417<br>
                            065-725-7398
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col cols="12">
                        <v-card-text class="pad0">Email : team@softway.co.th</v-card-text>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col
          cols='12'
          justify='center'
          align='center'
        >
          <v-spacer></v-spacer>
          <p class="white--text">
          Copyright © {{new Date().getFullYear()}} - Softway
          </p>
          <v-spacer></v-spacer>
        </v-col>
      </v-row>
    </v-footer>
</template>

<script>
export default {

}
</script>

<style>

</style>
