import { render, staticRenderFns } from "./OpenView.vue?vue&type=template&id=15ef2367&scoped=true&"
import script from "./OpenView.vue?vue&type=script&lang=js&"
export * from "./OpenView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15ef2367",
  null
  
)

export default component.exports