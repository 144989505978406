<template>
  <div>
    <v-divider/>
    <v-row no-gutters class="justify-center align-center" data-aos="fade-up" data-aos-anchor-placement="center-bottom">
      <v-col cols="11" sm="11" md="9" lg="9">
        <h1 class="white--text text-center mt-3 pb-3">ISO</h1>
        <h2 class="white--text">ระบบจัดเก็บเอกสาร ISO Document Management</h2>
        <p class="text-justify mb-6 white--text">
          การควบคุมเอกสารทั้งหมดที่เกี่ยวกับระบบคุณภาพ โดยเอกสารที่จัดทำขึ้นใหม่
          หรือได้รับการเปลี่ยนแปลง / ดัดแปลงต้องได้รับการทบทวน
          และอนุมัติโดยผู้มีอำนาจก่อนการเผยแพร่เอกสาร
          รวมถึงการกำหนดวิธีการแจกจ่ายเพื่อให้มั่นใจว่าเอกสารที่เกี่ยวข้องกับการปฏิบัติที่ถูกควบคุม
          ภายใต้ระบบคุณภาพ ISO มีอยู่ของแต่ละองค์กร
          ที่มีส่วนเกี่ยวข้องกับระบบคุณภาพ และเป็นเอกสารฉบับล่าสุด
          รวมทั้งการป้องกันการนำพาเอกสารที่ล้าสมัยแล้วไปใช้โดยไม่เจตนา
          นอกจากนี้ยังเป็นวิธีการในการควบคุมสถานการณ์รับ , การแจกจ่าย,
          การเก็บรักษา และการทำลายเอกสาร / แบบฟอร์มที่ล้าสมัยแล้ว
          ทั้งนี้เพื่อให้มั่นใจว่าแต่ละหน่วยงานใช้เอกสาร / แบบฟอร์มฉบับล่าสุด
          และสอดคล้องกับมาตรฐานระบบคุณภาพ ISO 9001 : 2000
        </p>
        <h2 class="white--text">
          วิเคราะห์และออกแบบโครงสร้างการจัดเก็บเอกสาร ISO Document เช่น Manual,
          Proceed,Form และ QC Sheet เป็นต้น ตาม Best Practice
        </h2>
        <p class="text-justify white--text">
          วิเคราะห์และออกแบบ listing document/content types, users/permission,
          Properties/metadata เป็น การวางโครงสร้างกลุ่มผู้ใช้งาน (user group)
          และสิทธิ์การเข้าถึงเอกสาร (Permission) ของกลุ่มผู้ใช้งาน ตั้งค่าระบบ
          Properties/metadata และ Permission และ Easy Capture และ Upload Plus
          Tools เพื่อจัดเก็บเอกสาร ISO ตั้งค่า ISO Document Center (Master List)
          เพื่อให้แสดงเอกสารตาม metadata ที่ออกแบบ และควบคุมการเข้าถึงเอกสาร
          ตั้งค่า Easy OCR เพื่อทำการ OCR ภาพเอกสารเพื่อให้สามารถค้นหาได้ (Full
          Text Search) วางโครงสร้างเพื่อรองรับการเชื่อมต่อกับ Workflow ในอนาคต
        </p>
      </v-col>
      <v-col cols="11" sm="11" md="3" lg="3">
        <v-img
          class="white--text align-end ml-0"
          height="auto"
          src="../../assets/static/images/service/a-iso.png"
        ></v-img>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
