<template>
  <div>
    <v-row no-gutters class="justify-center align-center" style="height: 100vh;" data-aos="fade-up" data-aos-anchor-placement="top-center">
        <v-col cols="10" sm="10" md="8" lg="9">
          <h1 class="pb-6 text-center white--text">เกี่ยวกับเรา</h1>
          <h2 class="text-center white--text">Softway Consltng</h2>
          <h4 class="text-center text-justify white--text">
            เป็นผู้เชี่ยวชาญละที่ปรึกษาชั้นนำ ของ Solution VDI
            หรือคอมพิวเตอร์เสมือนและ ระบบจัดเก็บเอกสารออนไลน์
            ให้บริการบนโครงสร้าง Cloud ที่มีมาตรฐาน และปลอดภัย
            มีความน่าเชื่อถือไว้ใจได้ ใส่ใจลูกค้าเป็นสำคัญโดยทำธุรกิจ่โปร่งใส
            และส่งต่อโอกาสให้รุ่นต่อไป
          </h4>
        </v-col>
    </v-row>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
