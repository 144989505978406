<template>
    <v-app>
        <LeftView></LeftView>
        <TopView></TopView>
        <v-main class="pa-0 widthfull">
            <v-content class="widthfull colorGradient">
                <router-view/>
            </v-content>
        </v-main>
        <Footer></Footer>
    </v-app>
</template>

<script>
import TopView from './component/TopView.vue'
import LeftView from './component/LeftView.vue'
import Footer from './component/FooterView.vue'
export default {
  components: {
    TopView,
    LeftView,
    Footer
  }
}

</script>

<style>

</style>
