import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      dark: {
        primary: '#04254e',
        accent: '#BC1B52',
        secondary: '#ffe18d',
        success: '#4CAF50',
        info: '#2196F3',
        warning: '#FB8C00',
        error: '#FF5252',
        mainOn: '#5D8D73',
        main2: '#9AD9DB'
      },
      light: {
        primary: '#2e79ac',
        accent: '#F5F5F6',
        secondary: '#6bbec7',
        footege: '#F5F5F5',
        success: '#4CAF50',
        info: '#2196F3',
        warning: '#FB8C00',
        error: '#FF5252',
        mainOn: '#5D8D73',
        menubar: '#276691',
        main2: '#9AD9DB'
      }
    }
  }
})
