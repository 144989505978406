<template>
  <div>
    <v-divider/>
    <v-row class="justify-center align-center" data-aos="fade-up" data-aos-anchor-placement="center-bottom">
      <v-col cols="11" sm="11" md="7" lg="7">
        <h1 class="white--text text-center mt-3 pb-3">DMS</h1>
        <h2 class="white--text text-center">Document Management System</h2>
        <p class="white--text text-justify mt-4">
          เป็นผู้ช่วยทีดีด้านการจัดเก็บและการสืบค้นหาในคลังเอกสารที่เก็บได้ทุกไฟล์ด้วยความรวดเร็ว แม่นยํา
          อีกทั้งยังลดความเสี่ยงหากเกิดเหตุจากภัยพิบัติเช่น ไฟใหม้ น้ำท่วม
          แผ่นดินไหว ที่ก่อให้เกิดเอกสารเสียหายหรือสาบสูญ ส่งผลกระทบให้งานตรวจสอบ
          Audit มีปัญหา
        </p>

        <p class="white--text text-justify mt-4">
          จัดเก็บไฟล์จากคอมพิวเตอร์ที่อยู่บน Client ของพนักงานภายในองค์กร เช่น
          Microsoft Word, Excel, PowerPoint หรือรูปแบบไฟล์JPG, JPEG, PDF, MP4
          ให้อยู่ในรูปแบบตามผังโครงสร้างขององค์กร
        </p>

        <p class="white--text text-justify mt-4">
          รวมถึงการแบ่งสิทธิ การเข้าถึงเอกสารของแต่ละฝ่าย แต่ละแผนก
          และการควบคุมเอกสารสําคัญ
          พร้อมด้วยฟังก์ชันระบบรักษาความปลอดภัยด้วยลายน้ำ แสดงบนหน้าเอกสาร
          ป้องกันการทุจริตและ การดาวน์โหลดเอกสารออกไปจากระบบของผู้ที่ไม่หวังดี
        </p>
      </v-col>
      <v-col cols="11" sm="11" md="5" lg="5">
        <v-img
          class="white--text align-end ml-0"
          height="auto"
          src="../../assets/static/images/service/bg_dms.png"
        ></v-img>
      </v-col>
    </v-row>
    <v-row class="justify-center align-center">
      <v-col cols="11" sm="11" md="4" lg="4">
        <h1 class="text-center white--text">
          หมดปัญหาความยุ่งยากด้วยระบบที่รองรับการทำงานได้อย่างมีประสิทธิภาพด้วย
        </h1>
      </v-col>
      <v-col cols="11" sm="11" md="8" lg="8">
        <v-row class="justify-center align-center">
          <v-col cols="11" sm="11" md="4" lg="4" v-for="(item, index) in serviceDetailTop" :key="index">
            <v-card elevation="0" color="transparent">
              <v-img :aspect-ratio="16 / 9" contain :src="item.image"></v-img>
              <h2 class="text-center white--text">{{ item.name }}</h2>
              <p class="text-justify white--text">{{ item.detail }}</p>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    serviceDetailTop: [
      { name: 'การจัดเก็บเอกสาร', image: require('@/assets/static/images/service/icon_dms1.png'), detail: 'นำเข้าเอกสารจากไฟล์ Word, Excel, PowerPoint, Text File, Adobe Acrobat File, JPG, JPEG, TIFF, PDF และไฟล์มัลติมีเดีย MPG, MPEG, AVI' },
      { name: 'การค้นหาเอกสาร', image: require('@/assets/static/images/service/a-digital_capture.png'), detail: 'การสืบค้นตามโครงสร้างการจักเก็บ และการสืบค้นตามดัชนีการค้นหาช่วยให้คุณสามารถค้นหาได้ง่ายและรวดเร็ว' },
      { name: 'ควบคุมสิทธิ์', image: require('@/assets/static/images/service/icon_dms3.png'), detail: 'การกำหนดสิทธิ์ในการเข้าถึงไฟล์เอกสาร และการกำหนดจัดเก็บเวอร์ชันเอกสารที่มีการเปลี่ยนแปลง (Version Control)' },
      { name: 'ความปลอดภัย', image: require('@/assets/static/images/service/icon_dms4.png'), detail: 'กำหนดสิทธิ์การเผยแพร่ข้อมูล เก็บประวัติการเข้าสู่ระบบ และการเข้าถึงข้อมูลไฟล์เอกสารด้วยการเข้ารหัสไฟล์เอกสารเพื่อป้องกันการถูกโจรกรรมข้อมูล' },
      { name: 'การดูแลระบบ', image: require('@/assets/static/images/service/icon_dms5.png'), detail: 'การสำรองข้อมูล(Back Up) ไฟล์เอกสารอย่างมีประสิทธิภาพ รวมถึงรองรับการเพิ่มจำนวนพื้นที่ในการจัดเก็บเอกสารได้สะดวก รวดเร็ว ง่ายได้' },
      { name: 'คุณสมบัติด้านอื่นๆ', image: require('@/assets/static/images/service/icon_dms6.png'), detail: 'รองรับการทำงานในรูปแบบ Web Application ตอบรับการทำงานได้หลากหลายอุปกรณ์ อีกทั้งยังสะดวกต่อการใช้งานภายในองค์กร หรือภายนอกองค์กร' }
    ]
  })
}
</script>

<style></style>
