<template>
    <v-container fluid>
    <v-row no-gutters class="justify-center">
      <v-col cols="12" sm="12" md="10" lg="9">
        <section id="home">
           <OpenView></OpenView>
        </section>

        <section id="about">
            <AboutView></AboutView>
        </section>

        <section id="service">
            <ServiceView></ServiceView>
        </section>

          <section id="vapp">
          <VappView></VappView>
        </section>

        <section id="dms">
          <DMSView></DMSView>
        </section>

        <section id="iso">
          <IsoView></IsoView>
        </section>

        <section id="scan">
          <ScanDocument></ScanDocument>
        </section>

         <section id="contact">
          <ContactView></ContactView>
        </section>
      </v-col>
    </v-row>
    </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import OpenView from '../components/home/OpenView.vue'
import AboutView from '../components/home/AboutView.vue'
import ServiceView from '../components/home/ServiceView.vue'
import VappView from '../components/service/Ver2Vapp.vue'
import DMSView from '../components/service/Ver2DMS.vue'
import ContactView from '../components/home/ContactView.vue'
import IsoView from '../components/service/IsoDocument.vue'
import ScanDocument from '../components/service/Ver2ScanDocument.vue'
export default {
  components: {
    OpenView,
    AboutView,
    ContactView,
    ServiceView,
    VappView,
    DMSView,
    IsoView,
    ScanDocument

  },
  created () {
    this.setIsPagePDPA(false)
  },
  methods: {
    ...mapMutations([
      'setIsPagePDPA'
    ])
  }
}
</script>
<style scoped>
.setFullheight{
    height: 91vh;
}
</style>
