<template>
  <div>
    <v-row no-gutters class="justify-center align-center">
        <v-col cols="9">
            <v-img class="mb-4" height="auto" src="../assets/static/images/pdpa/นโยบายการคุ้มครองข้อมูลส่วนบุคคล001.jpg"></v-img>
            <v-img class="mb-4" height="auto" src="../assets/static/images/pdpa/นโยบายการคุ้มครองข้อมูลส่วนบุคคล002.jpg"></v-img>
            <v-img class="mb-4" height="auto" src="../assets/static/images/pdpa/นโยบายการคุ้มครองข้อมูลส่วนบุคคล003.jpg"></v-img>
            <v-img class="mb-4" height="auto" src="../assets/static/images/pdpa/นโยบายการคุ้มครองข้อมูลส่วนบุคคล004.jpg"></v-img>
            <v-img class="mb-4" height="auto" src="../assets/static/images/pdpa/นโยบายการคุ้มครองข้อมูลส่วนบุคคล005.jpg"></v-img>
            <v-img class="mb-4" height="auto" src="../assets/static/images/pdpa/นโยบายการคุ้มครองข้อมูลส่วนบุคคล006.jpg"></v-img>
            <v-img class="mb-4" height="auto" src="../assets/static/images/pdpa/นโยบายการคุ้มครองข้อมูลส่วนบุคคล007.jpg"></v-img>
            <v-img class="mb-4" height="auto" src="../assets/static/images/pdpa/นโยบายการคุ้มครองข้อมูลส่วนบุคคล008.jpg"></v-img>
            <v-img class="mb-4" height="auto" src="../assets/static/images/pdpa/นโยบายการคุ้มครองข้อมูลส่วนบุคคล009.jpg"></v-img>
            <v-img class="mb-4" height="auto" src="../assets/static/images/pdpa/นโยบายการคุ้มครองข้อมูลส่วนบุคคล010.jpg"></v-img>
            <v-img class="mb-4" height="auto" src="../assets/static/images/pdpa/นโยบายการคุ้มครองข้อมูลส่วนบุคคล011.jpg"></v-img>
            <v-img class="mb-4" height="auto" src="../assets/static/images/pdpa/นโยบายการคุ้มครองข้อมูลส่วนบุคคล012.jpg"></v-img>
            <v-img class="mb-4" height="auto" src="../assets/static/images/pdpa/นโยบายการคุ้มครองข้อมูลส่วนบุคคล013.jpg"></v-img>
        </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  created () {
    this.setIsPagePDPA(true)
  },
  methods: {
    ...mapMutations([
      'setIsPagePDPA'
    ])
  }
}
</script>

<style></style>
