<template>
  <div>
    <v-divider/>
    <v-layout row wrap justify-center align-center data-aos="fade-up" data-aos-anchor-placement="center-bottom">
      <v-col cols="11" sm="11" md="6" lg="7">
        <h1 class="white--text text-center">Scan Document</h1>
        <h2 class="mainColor">ระบบสแกนเอกสาร</h2>
        <p class="text-justify white--text">
            บริการสแกนเอกสารนอกสถานที่ แบบครบวงจร เอกสารขนาด A4,A3 พร้อมการทำดัชนีค้นหา เพื่อใช้ในการค้นหาเอกสารได้ทุกที่ ทุกเวลา
        </p>
        <p class="text-justify white--text"> <v-icon color="green">mdi-check-decagram</v-icon> ลดปัญหาเอกสารสูญหาย </p>
        <p class="text-justify white--text"> <v-icon color="green">mdi-check-decagram</v-icon> ลดการค้นหาเอกสารเดิมได้ล่าช้า </p>
        <p class="text-justify white--text"> <v-icon color="green">mdi-check-decagram</v-icon> ลดปริมาณงานการจัดเก็บเอกสาร </p>
        <p class="text-justify white--text"> <v-icon color="green">mdi-check-decagram</v-icon> กำหนดงบประมาณและค่าใช้จ่ายได้ </p>
        <p class="text-justify white--text"> <v-icon color="green">mdi-check-decagram</v-icon> กำหนดระยะเวลาการดำเนินการได้ทันที </p>
      </v-col>
      <v-col cols="11" sm="11" md="3" lg="3">
        <v-img
          class="align-end"
          height="auto"
          src="../../assets/static/images/service/bg_scan_document.png"
        ></v-img>
      </v-col>
    </v-layout>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
