import { render, staticRenderFns } from "./Page404View.vue?vue&type=template&id=405f87f7&scoped=true&"
var script = {}
import style0 from "./Page404View.vue?vue&type=style&index=0&id=405f87f7&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "405f87f7",
  null
  
)

export default component.exports