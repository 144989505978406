<template>
  <div>
    <!-- <v-container fluid>
      <v-row no-gutters class="justify-center align-center">
        <v-col cols="12" sm="12" md="4" lg="3">
          <h1 class="text-center white--text" data-aos="fade-right" data-aos-anchor-placement="bottom-bottom">Services</h1>
        </v-col>
        <v-col cols="12" sm="12" md="8" lg="9">
          <v-row ma-0 pa-0 no-gutters class="justify-center align-center">
            <v-col cols="12" xs="12" sm="6" md="4" lg="4" v-for="(item, index) in serviceDetailTop" :key="index">
                <v-card :elevation="hover ? 2 : 0" class="transparent" height="350" data-aos="fade-left" data-aos-anchor-placement="bottom-bottom">
                  <v-row no-gutters class="justify-center align-center" @click="goDirec(item.tag)">
                    <v-col cols="4">
                        <v-img :src="item.image"/>
                    </v-col>
                    <v-col cols="12">
                        <h1 class="text-center white--text">{{ item.name }}</h1>
                    </v-col>
                    <v-col cols="12">
                        <h4 class="text-center white--text">{{ item.detail }}</h4>
                    </v-col>
                  </v-row>
                </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container> -->
    <v-row class="justify-center align-center">
      <v-col cols="11" sm="11" md="4" lg="4">
        <h1 class="text-center white--text" data-aos="fade-up" data-aos-anchor-placement="center-bottom">
          Services
        </h1>
      </v-col>
      <v-col cols="11" sm="11" md="8" lg="8">
        <v-row class="justify-center align-center">
          <v-col cols="11" sm="11" md="4" lg="4" v-for="(item, index) in serviceDetailTop" :key="index">
            <v-card elevation="0" color="transparent" min-height="350px" class="mx-1" @click="goDirec(item.tag)">
              <v-img :aspect-ratio="16 / 9" contain :src="item.image"></v-img>
              <h2 class="text-center white--text">{{ item.name }}</h2>
              <p class="text-justify white--text">{{ item.detail }}</p>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  components: {},
  data: () => ({
    serviceDetailTop: [
      {
        tag: 'vapp',
        name: 'V-app',
        image: require('@/assets/static/images/service/a-vapp.png'),
        list: ['สะดวก', 'รวดเร็ว', 'ง่ายดาย'],
        detail:
          'บริการแอพพลิเคชั่นเปลี่ยนแปลง Legacy Software ที่ไม่ยืดหยุ่นเปลี่ยนมาใช้ในรูปแบบ Online ได้อย่างง่ายดาย'
      },
      {
        tag: 'dms',
        name: 'DMS',
        image: require('@/assets/static/images/service/a-dms.png'),
        list: ['ลดค่าใช้จ่าย', 'เพิ่มประสิทธิภาพ', 'ลดการใช้กระดาษ'],
        detail:
          'เป็นผู้ช่วยที่ดีด้านการจัดเก็บ และการสืบค้นหาในคลังเอกสารที่เก็บได้ทุกไฟล์ ด้วยความรวดเร็ว แม่นยำ '
      },
      {
        tag: 'iso',
        name: 'ISO',
        image: require('@/assets/static/images/service/a-iso.png'),
        list: ['สร้างรายการคำขอ', 'ส่งคำขออนุมัติ', 'การเผยแพร่เอกสาร'],
        detail:
          'การควบคุมเอกสารตามกระบวณการ มั่นใจได้ว่าเอกสารที่เกี่ยวข้องอยู่ภายใต้ระบบคุณภาพ ISO'
      },
      {
        tag: '',
        name: 'Digital Capture',
        image: require('@/assets/static/images/service/a-digital_capture.png'),
        list: [
          'เปลี่ยนไฟล์ภาพเป็นอักษร',
          'คัดแยกประเภทเอกสาร',
          'จัดเก็บไฟล์รูปแบบอัตโนมัติ'
        ],
        detail:
          'การแปลงไฟล์จากเอกสารธรรมดาให้ออกมาในรูปแบบ Text ด้วยเทคโนโลยี่ OCR มีทำกระบวณดึงข้อมูลไปใช้ในการทำดัชนีสำหรับการค้นหา'
      },
      {
        tag: 'scan',
        name: 'Scan Document',
        image: require('@/assets/static/images/service/a-scan_document.png'),
        list: ['ประหยัดเวลา', 'ประหยัดค่าใช้จ่าย', 'บริการถึงไซค์งาน'],
        detail:
          'บริการสแกนเอกสารนอกสถานที่ แบบครบวงจร เอกสารขนาด A4,A3 พร้อมการทำดัชนีค้นหา เพื่อใช้ในการค้นหาเอกสารได้ทุกที่ ทุกเวลา'
      },
      {
        tag: '',
        name: 'SmartPrint',
        image: require('@/assets/static/images/service/a-smart_print.png'),
        list: [
          'ไม่จําเป็นต้องติดตั้ง Driver Printer',
          'สั่งพิมพ์ไปยังเครื่องในองค์กรได้',
          'บริการถึงไซค์งาน'
        ],
        detail:
          'เป็นซอฟต์แวร์การพิมพ์ผ่าน RDP สำหรับการทำงานของ Terminal Service'
      }
    ]
  }),
  methods: {
    goDirec (i) {
      if (i !== '') {
        this.$vuetify.goTo('#' + i)
      }
    }
  }
}
</script>

<style scoped></style>

<!-- <v-hover v-slot:default="{ hover }"> -->
