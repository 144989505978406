<template>
  <div>
    <v-row no-gutters class="justify-center align-center" style="height: 100vh;">
      <v-col cols="12" sm="12" md="7" lg="6">
        <h1 class="text-center white--text" data-aos="fade-up">บริการโซลูชั่นทรานฟอร์มอนาล็อกเข้าสู่ดิจิทัล</h1>
        <h4 class="text-center white--text pt-3" data-aos="fade-up">
          ทีมงานมีความชำนาญและเป็นผู้นำทางด้านการ Convert Application
          รูปแบบออนไลน์
          และเราจะส่งเสริมการให้บริการด้วยเทคโนโลยีสมัยใหม่ที่ช่วยให้การสืบค้นหาเอกสาร
          รวดเร็ว ปลอดภัย น่าเชื่อถือ ตอบโจทย์ทุกความต้องการ
        </h4>
      </v-col>
      <v-col cols="12" sm="12" md="5" lg="6" data-aos="zoom-in">
        <v-img
          class="white--text align-end ml-0"
          height="auto"
          src="../../assets/static/images/home_Main.png"
        ></v-img>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
</style>
