<template>
  <div>
    <v-divider/>
    <v-row no-gutters class="justify-center align-center" data-aos="fade-up" data-aos-anchor-placement="center-bottom">
      <v-col cols="11" sm="11" md="7" lg="7">
        <h1 class="text-center white--text mt-3 pb-3">V-app</h1>
        <h2 class="white--text pb-3">ปัญหาของลูกค้าคืออะไร</h2>
        <p class="white--text pb-10">
          Legacy Application Not Compatible เมื่อเทคโนโลยีก้าวหน้าไปอย่างรวดเร็ว
          ทําให้ องค์กรและหน่วยงาน IT
          ต้องเร่งปรับตัวเพื่อรองรับเทคโนโลยีที่เปลี่ยนแปลงจากปัญหา Legacy
          Application ที่ไม่ยืดหยุ่น การมีบริการ Application as a Service (AaaS)
          เพื่อใช้เป็นศูนย์กลางในการบริหารจัดการทําให้สามารถรองรับ Applications
          ทั้งเก่าและใหม่ได้ จึงเป็นเรื่องสําคัญ
        </p>

        <h2 class="white--text pb-3">สิ่งที่เราทำให้ลูกค้า</h2>
        <p class="white--text">
          V-App ต่อยอดธุรกิจ เพิ่มทางเลือกการให้บริการ Software/Application
          ของคุณ ด้วยแพลตฟอร์มที่ทันสมัย ทําให้เพิ่มช่องทางการขาย
          และควบคุมนโยบายการใช้งาน ข้อมูลและซอฟแวร์ของคุณได้ตามต้องการ
          ทําให้มั่นใจได้ว่า Software/Application ของคุณ
          จะได้อยู่ในระบบที่มีความปลอดภัยสูงสุด เพื่อการดําเนินการทางธุรกิจที่สะดวก
          สบายไร้ขีดจํากัด
        </p>
      </v-col>
      <v-col cols="11" sm="11" md="5" lg="5">
        <v-img
          class="white--text align-end"
          height="auto"
          src="../../assets/static/images/service/bg_vapp3.png"
        ></v-img>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {}
</script>

<style>
</style>
