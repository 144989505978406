import Vue from 'vue'
import VueRouter from 'vue-router'
// import NavigationbarView from '../components/NavigationbarView.vue'
// import HomeView from '../views/HomeView.vue'
// import AboutView from '../views/AboutView.vue'
// import PdpaView from '../views/PdpaView.vue'
import PagePrivacyPolicy from '../views/PagePrivacyPolicy.vue'
// import ServiceWorkflow from '../views/ServiceAdvanceWorkFlowView.vue'
// import ServiceDMS from '../views/ServiceDMSView.vue'
// import ServiceISO from '../views/ServiceISOView.vue'
// import ServiceScan from '../views/ServiceScanView.vue'
// import ServicePrint from '../views/ServiceSmartPrintView.vue'
// import ServiceVapp from '../views/ServiceVappView.vue'
// import ServiceVdi from '../views/ServiceVdiView.vue'
import PageNotFound from '@/views/Page404View.vue'
import V2NavigationbarView from '../components/navigation/NavigationView.vue'
import V2Home from '../views/Ver2Home.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/PageNotFound',
    name: 'PageNotFound',
    component: PageNotFound
  },
  // {
  //   path: '*',
  //   redirect: '/PageNotFound'
  // },
  {
    path: '/',
    component: V2NavigationbarView,
    children: [{
      path: '/',
      name: V2Home,
      component: V2Home
    },
    {
      path: '/privacy-policy',
      name: PagePrivacyPolicy,
      component: PagePrivacyPolicy
    }]
  }
  // {
  //   path: '/unuse',
  //   component: NavigationbarView,
  //   children: [{
  //     path: '/gg',
  //     name: HomeView,
  //     component: HomeView
  //   },
  //   {
  //     path: 'about/dms',
  //     name: ServiceDMS,
  //     component: ServiceDMS
  //   },
  //   {
  //     path: 'about/iso',
  //     name: ServiceISO,
  //     component: ServiceISO
  //   },
  //   {
  //     path: 'about/scan',
  //     name: ServiceScan,
  //     component: ServiceScan
  //   },
  //   {
  //     path: 'about/vapp',
  //     name: ServiceVapp,
  //     component: ServiceVapp
  //   },
  //   {
  //     path: 'about/vdi',
  //     name: ServiceVdi,
  //     component: ServiceVdi
  //   },
  //   {
  //     path: 'about/contact',
  //     name: AboutView,
  //     component: AboutView
  //   }]
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
