<template>
  <v-bottom-sheet persistent hide-overlay v-model="sheet" inset>
    <v-sheet class="text-center" height="auto">
      <div class="pt-3 pl-3 pr-3">
        บริษัทฯ มีความจำเป็นอย่างยิ่งที่จะต้องแจ้งนโยบายของเว็บไซต์ภายในบริษัทฯ ท่านสามารถศึกษารายละเอียดของเอกสารนโยบาย ได้ที่นี่
      </div>
      <v-btn text color="success" @click="onRead()">
        อ่าน
      </v-btn>
      <v-btn text color="error" @click="onClose()">
        ปิด
      </v-btn>
    </v-sheet>
  </v-bottom-sheet>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
export default {
  computed: {
    ...mapState([
      'isPagePDPA'
    ])
  },
  created () {
    this.firstChaeckOnOpen()
  },
  data () {
    return {
      read: false,
      sheet: false
    }
  },
  methods: {
    ...mapMutations([
      'setIsPagePDPA'
    ]),
    firstChaeckOnOpen () {
      console.log('Show', localStorage.getItem('close'))
      if (localStorage.getItem('close') === null) {
        this.sheet = true
        localStorage.setItem('close', true)
      } else {
        if (localStorage.getItem('close') === 'true') {
          console.log('1case')
          this.sheet = true
        } else {
          console.log('2case')
          this.sheet = false
        }
      }
    },
    onClose () {
      this.sheet = false
      localStorage.setItem('close', false)
    },
    onRead () {
      this.setIsPagePDPA(true)
      this.$router.push('/privacy-policy')
    }
  }
}
</script>
