<template>
  <v-app>
    <router-view/>
    <PDPA></PDPA>
  </v-app>
</template>

<style>
.mainColor {
  color: #248AA2
}
.mainColor2 {
  color: #51C2D5;
}
.widthfull {
  width: 100%;
}
.m-0 {
  margin: 0 !important;
}
.text-justify{
  color: #595959;
}
.colorGradient {
background: rgb(107,190,199);
background: -moz-linear-gradient(90deg, rgba(107,190,199,1) 26%, rgba(46,121,172,1) 73%);
background: -webkit-linear-gradient(90deg, rgba(107,190,199,1) 26%, rgba(46,121,172,1) 73%);
background: linear-gradient(90deg, rgba(107,190,199,1) 26%, rgba(46,121,172,1) 73%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6bbec7",endColorstr="#2e79ac",GradientType=1);
  }
</style>

<script>
import PDPA from '@/views/PdpaView.vue'
export default {
  components: {
    PDPA
  }
}
</script>
